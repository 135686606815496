@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  display: flex;
  height: 90vh;
}


.main-page{
  position: absolute;
  width: 590px;
  height: 90%;
  left: 412px;
  top: 5%;
  background: #FFFFFF;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1), 0px 8px 53px rgba(0, 120, 240, 0.25);
  border-radius: 10px;
}

.nav-bar{
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  height: 95vh;
  flex-grow: 1;
  background: white;
  top: 0;
  left: 0;
}


.page-icon{
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  left: 245px;

  background: rgba(0, 120, 240, 0.02);
  box-shadow: 0px 4px 4px rgba(0, 120, 240, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 120, 240, 0.1);
  border-radius: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0078F0;
  font-size: 50px;
}

.page-title{
  font-style: normal;
  font-weight: 100;
  font-size: 26px;
  line-height: 31px;

  display: flex;
  align-items: flex-end;
  color: rgba(0, 120, 240, 0.5);
}



